'use client';

// Third Party Plugins
import React, { useEffect, useState } from 'react';
import Image from 'next/image';
import Masonry from 'react-masonry-css';
import gsap from 'gsap';
import { useInView } from 'react-intersection-observer';
// Types
import type { GalleryProps } from './types';
// Styles
import { StyledSection, StyledGallery } from './styles';

const Gallery: React.FC<GalleryProps> = ({ images }) => {
  const { ref: galleryRef, inView: isInView } = useInView({ threshold: 0.2 });
  const [wasEverVisible, setWasEverVisible] = useState<boolean>(false);

  // Define breakpoints for responsive masonry layout
  const breakpoints: { [key: string]: number } = {
    default: Math.min(images?.length || 0, 3) || 1,
    1000: Math.min(images?.length || 0, 3) || 1,
    768: Math.min(images?.length || 0, 2) || 1,
    480: 1,
  };

  useEffect(() => {
    // Track if the gallery section has ever been visible to the user
    if (isInView && !wasEverVisible) {
      setWasEverVisible(true);
    }
  }, [isInView, wasEverVisible]);

  // Animate images when they are visible to the user
  useEffect(() => {
    if (wasEverVisible) {
      images.forEach((_, index) => {
        gsap.fromTo(
          `.gallery .image-wrapper-${index}`,
          { opacity: 0, y: 200 },
          { opacity: 1, y: 0, duration: 1, stagger: 0.1 },
        );
      });
    }
  }, [images, wasEverVisible]);

  // Animate a single image when it intersects the viewport
  const animateImage = (entry: IntersectionObserverEntry) => {
    if (entry.isIntersecting) {
      gsap.fromTo(
        entry.target,
        { opacity: 0, y: 200 },
        { opacity: 1, y: 0, duration: 1 },
      );
    }
  };

  useEffect(() => {
    let observer: IntersectionObserver | undefined;

    // Create intersection observer if the browser supports it
    if (
      typeof window !== `undefined` &&
      typeof IntersectionObserver !== `undefined`
    ) {
      observer = new IntersectionObserver(([entry]) => animateImage(entry), {
        threshold: 0.2,
      });

      // Observe all image-wrapper elements
      const imageWrappers = document.querySelectorAll(`.image-wrapper`);
      imageWrappers.forEach((element) => {
        observer?.observe(element);
      });
    }

    // Clean up the observer when the component is unmounted or updated
    return () => {
      observer?.disconnect();
    };
  }, []);

  // Determine which Masonry component to render based on the type
  const masonryComponent =
    images && images.length > 0 ? (
      <Masonry
        breakpointCols={breakpoints}
        className="masonry"
        columnClassName="masonry_column"
        style={{ visibility: wasEverVisible ? `visible` : `hidden` }}
      >
        {/* Render images */}
        {images.map((image, index) => (
          <div
            key={image.asset.url} // Using asset.url as a key since id is optional
            className={`image-wrapper image-wrapper-${index}`}
          >
            {image.asset.url && (
              <Image
                src={image.asset.url}
                alt={image.alt || ``}
                width={500} // Adjust these values based on your requirements
                height={500} // Adjust these values based on your requirements
                loading="lazy"
                style={{ width: 'auto', height: 'auto' }} // Maintain aspect ratio
              />
            )}
          </div>
        ))}
      </Masonry>
    ) : null;

  // Render the Gallery component
  return (
    <StyledSection>
      <StyledGallery className="gallery" ref={galleryRef}>
        {masonryComponent}
      </StyledGallery>
    </StyledSection>
  );
};

export default Gallery;
