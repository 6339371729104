'use client';

import styled from 'styled-components';

// export const StyledSection = styled.section`
//   padding: 20px;
//   background-color: #f9f9f9;
//   border-radius: 8px;
//   margin-bottom: 20px;
// `;
//
//
export const StyledSection = styled.section`
  margin: 2rem 0;

  // div {
  //   display: flex;
  //   gap: 1rem;
  // }

  // a {
  //   text-decoration: none;
  //   color: #fff;
  //   background-color: #007bff;
  //   padding: 1rem 2rem;
  //   border-radius: 5px;
  //   display: inline-block;
  //
  //   &:nth-child(2) {
  //     background-color: #28a745;
  //   }
  //
  //   &:hover {
  //     background-color: #0056b3;
  //   }
  //
  //   &:nth-child(2):hover {
  //     background-color: #218838;
  //   }
  // }

  // .link-button {
  //   text-decoration: none;
  //   color: #fff;
  //   background-color: #007bff;
  //   padding: 1rem 2rem;
  //   border-radius: 5px;
  //   display: inline-block;
  // }
  //
  // .link-button + .link-button {
  //   margin-left: 1rem;
  // }

  .popular-sections {
    margin-top: 2rem;
  }

  .popular-sections ul {
    list-style: none;
    padding: 0;
  }

  .popular-sections li {
    margin-bottom: 0.5rem;
  }
`;
